import { DomainData } from "shared/types/domain-data";
import apiClient from "./ApiClient";

export async function getOrgData(orgId: string, role: string): Promise<DomainData> {
  return await apiClient.get(`${orgId}/organization/`, role, undefined, false, true);
}

export const createApiKey = async (orgId: string, role: string, payload: any) => {
  return await apiClient.post(`${orgId}/organization/api_key/`, role, payload, undefined);
};

export const deleteApiKey = async (orgId: string, role: string, apiKeyId: string) => {
  return await apiClient.delete(`${orgId}/organization/api_key/${apiKeyId}/`, role, undefined);
};
