"use client";

import { convertKeysToCamelCase } from "@repo/utils";
import { usePostHog } from "posthog-js/react";
import { PropsWithChildren, useEffect, useState } from "react";
import { isDomainData } from "shared/types/domain-data";
import { getOrgData } from "../services/orgService";
import { getOrgIdFromHost } from "../utils/utils";
import type { StoreInterface, StoreType } from "./store";
import { Provider, initializeStore } from "./store";
export interface PreloadedStoreInterface extends Pick<StoreInterface, "domainData"> {}

interface StoreProviderProps extends PropsWithChildren<PreloadedStoreInterface> {
  host: string;
}

export default function StoreProvider({ children, host }: StoreProviderProps) {
  const [store, setStore] = useState<StoreType | null>(null);
  const posthog = usePostHog();

  useEffect(() => {
    const fetchAndInitializeStore = async () => {
      const orgId = getOrgIdFromHost(host);
      const postHogOrg = orgId;

      if (!orgId) {
        console.error("No orgId found in host");
        return;
      }

      const response = await getOrgData(orgId, "Admin");
      const domainData = convertKeysToCamelCase(response);

      if (isDomainData(domainData)) {
        setStore(
          initializeStore({
            domainData,
          }),
        );
      }

      // Identify organization with posthog
      if (postHogOrg) {
        posthog.group("organization", postHogOrg, {
          name: postHogOrg,
        });
      }
    };

    fetchAndInitializeStore();
  }, [host]);

  if (store === null) {
    return null;
  }

  return <Provider value={store}>{children}</Provider>;
}
