"use client";
import { signOut } from "firebase/auth";
import { Tokens } from "next-firebase-auth-edge";
import { useRouter } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { createContext, useContext, useEffect, useState } from "react";
import { getFirebaseAuth } from "../firebase";
import { User } from "../interfaces";
import apiClient from "../services/ApiClient";
import { getUser } from "../services/userService";
import { useStore } from "../store/store";

interface AuthContextProps {
  user: User | null;
  setUser: (user: User | null) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children, userToken }: { children: React.ReactNode; userToken: Tokens | null }) => {
  const domainData = useStore((state) => state.domainData);
  const orgId = domainData?.orgId;
  const [user, setUserState] = useState<User | null>(null);
  const updateCurrentSession = useStore((state) => state.updateCurrentSession);
  const router = useRouter();
  const posthog = usePostHog();

  const setUser = async (amigoUser: User | null) => {
    /* Uncomment to set httpOnly cookie reflecting amigo user data
     * This was previously used in next middleware to redirect user based on role
     * However this led to issues in the middleware before the cookie was populated.
     * The app is now using client-side role based redirection in the root layouts.
     */
    //await setHttpOnlyAmigoUserCookie(amigoUser);
    setUserState(amigoUser);
  };

  async function logout() {
    const auth = getFirebaseAuth();
    if (auth) {
      await signOut(auth);
      await fetch("/api/logout");
      router.push("/login?reload=true");
      updateCurrentSession(null);
      setUser(null);
      posthog.reset();
      router.refresh();
    }
  }

  /** Init amigo user data once userToken is ready */
  useEffect(() => {
    const initAmigoUser = async () => {
      if (!userToken) {
        return;
      }

      /* 
         Set the initial idToken from next-firebase-auth-edge on the ApiClient.
         This token attached to outgoing requests
      */
      apiClient.serverIdToken = userToken.token;

      let userResponse = await getUser({
        orgId,
        role: "Admin",
        userId: userToken.decodedToken.uid,
        showToast: true,
      });

      const preferences = userResponse?.preferences || { enable_response_recommendation: false };
      const current_subscription = userResponse?.current_subscription;

      const amigoUser: User = {
        org_id: orgId,
        user_id: userResponse.user_id,
        email_verified: userResponse.email_verified,
        is_admin: userResponse.is_admin,
        name: userResponse.name,
        id: userResponse.user_id,
        email: userResponse.email,
        current_subscription,
        preferences,
      };

      setUser(amigoUser);

      // Identify user in PostHog
      posthog.identify(amigoUser.user_id, {
        orgId,
        email: amigoUser.email,
        name: amigoUser.name,
      });
    };

    initAmigoUser();
  }, [userToken]);

  return <AuthContext.Provider value={{ user, setUser, logout }}>{children}</AuthContext.Provider>;
};
