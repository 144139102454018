"use client";

import { useStore } from "@/shared_lib/store/store";

interface ClientLogoProps {
  altText?: string;
  overrideLogoUrl?: string;
}

export const Favicon = ({ overrideLogoUrl }: ClientLogoProps) => {
  const domainData = useStore((state) => state.domainData);

  const logo = overrideLogoUrl || domainData?.favicon || "";
  const src = `data:image/png;base64,${encodeURIComponent(logo)}`;
  return <link rel="icon" href={src} type="image/png" fetchPriority="high" />;
};
