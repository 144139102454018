"use client";
import { getCookie } from "cookies-next";
import { COOKIE_CONSENT } from "lib/constants/cookie";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (typeof window !== "undefined") {
  const flags = getCookie("bootstrapData");
  const cookieConsent = getCookie(COOKIE_CONSENT);

  let bootstrapData: any = {};

  if (flags) {
    bootstrapData = JSON.parse(flags);
  }

  if (cookieConsent) {
    const preferences = JSON.parse(cookieConsent);
    console.log("preferences", preferences);
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
      capture_pageview: false,
      // Uncomment to disable session recordings in the local development environment
      // disable_session_recordings: process.env.NODE_ENV === "development",
      disable_session_recording: !preferences.analytics,
      disable_persistence: !preferences.analytics,
      disable_cookie: !preferences.analytics,
      disable_external_dependency_loading: !preferences.analytics,
      disable_surveys: !preferences.analytics,
      disable_web_experiments: !preferences.analytics,
      bootstrap: bootstrapData,
    });
  } else {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
      capture_pageview: false,
      // Uncomment to disable session recordings in the local development environment
      // disable_session_recordings: process.env.NODE_ENV === "development",
      bootstrap: bootstrapData,
    });
  }
}

export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
